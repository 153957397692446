















import { mapActions, mapGetters } from 'vuex';
import LoyaltyPromoBuildCommunity from '@/views/LoyaltyPromotionView/loyaltyPromotionStepsComponents/LoyaltyPromoBuildCommunity.vue';
import LoyaltyPromoCreateCommunication from '@/views/LoyaltyPromotionView/loyaltyPromotionStepsComponents/LoyaltyPromoCreateCommunication.vue';
import LoyaltyPromoInviteMarketing from '@/views/LoyaltyPromotionView/loyaltyPromotionStepsComponents/LoyaltyPromoInviteMarketing.vue';
import LoyaltyPromoLaunchPage from '@/views/LoyaltyPromotionView/loyaltyPromotionStepsComponents/LoyaltyPromoLaunchPage.vue';
import LoyaltyPromotionDescription from '@/views/LoyaltyPromotionView/LoyaltyPromotionDescription.vue';
import { EOnbordingStep, TOnboardingPayloadStepType } from '@/api/schema';
import { isSuperAdmin } from '@/helpers/superAdminHelper';

export default {
  name: 'LoyaltyPromotionView',
  components: {
    LoyaltyPromoBuildCommunity,
    LoyaltyPromoCreateCommunication,
    LoyaltyPromoInviteMarketing,
    LoyaltyPromoLaunchPage,
    LoyaltyPromotionDescription
  },
  data: (): any => ({
    expandSteps: {
      [EOnbordingStep.buildCommunityProgress]: false,
      [EOnbordingStep.createAutoCommunicationProgress]: false,
      [EOnbordingStep.inviteMarketingManagerProgress]: false,
      [EOnbordingStep.launchLoyaltyPageProgress]: false
    }
  }),
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    steps(): any {
      return Object.freeze([
        { component: 'LoyaltyPromoInviteMarketing' },
        { component: 'LoyaltyPromoLaunchPage' },
        { component: 'LoyaltyPromoBuildCommunity' },
        { component: 'LoyaltyPromoCreateCommunication' }
      ]);
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null, oldId: number | null): void {
        if (newId) {
          if (
            (oldId || isSuperAdmin) &&
            this.$role.is([
              'account',
              'admin',
              'super-admin',
              'marketer',
              'developer'
            ])
          ) {
            this.getOnboardingProgress(newId);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Onboarding', [
      'getOnboardingProgress',
      'updateOnboardingProgress'
    ]),
    updateLoyaltyPromotionProgressHandler(
      stepType: TOnboardingPayloadStepType
    ): void {
      this.updateOnboardingProgress(stepType);
    },
    switchExpandedSteps(
      stepType: TOnboardingPayloadStepType,
      isExpandValue: boolean
    ): void {
      if (isExpandValue) {
        Object.keys(this.expandSteps).forEach((key) => {
          this.expandSteps[key] = key === stepType;
        });
      } else {
        this.expandSteps[stepType] = false;
      }
    }
  }
};
