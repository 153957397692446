





























































































import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import LaunchPageIcon from '@/assets/images/icons/launch-page-icon.svg?inline';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';

export default {
  name: 'LoyaltyPromoLaunchPage',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    LaunchPageIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.launchLoyaltyPageProgress
  }),
  methods: {
    done(): void {
      this.$emit('updateProgress', this.onboardingStepName);
      this.$emit('switchExpanded', this.onboardingStepName, false);
    }
  }
};
