










import LoyaltyPromotionDescriptionImage from '@/assets/images/loyaltyPromotion/loyalty-promotion-description.svg?inline';
import ExternalLink from '@/components/links/ExternalLink.vue';

export default {
  name: 'LoyaltyPromotionDescription',
  components: {
    LoyaltyPromotionDescriptionImage,
    ExternalLink
  }
};
