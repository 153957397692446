












import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import InviteManagerIcon from '@/assets/images/icons/invite-manager-icon.svg?inline';
import { EOnbordingStep, ERole } from '@/api/schema';
import InviteUserInput from '@/components/invite-user/InviteUserInput.vue';
import onboardingMixin from '@/mixins/Onboarding/OnboardingMixin';

export default {
  name: 'LoyaltyPromoInviteMarketing',
  mixins: [onboardingMixin],
  components: {
    ExpansionProgressPanel,
    InviteUserInput,
    InviteManagerIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.inviteMarketingManagerProgress,
    email: '',
    type: ERole.ROLE_OPERATOR_MARKETER
  }),
  methods: {
    handleInvite(): void {
      this.$emit('updateProgress', this.onboardingStepName);
    }
  }
};
