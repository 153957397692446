




























import capitalize from 'lodash/capitalize';
import onboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import { EOnbordingStep, EWidgetSocialMedia } from '@/api/schema';
import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import BuildCommunityIcon from '@/assets/images/icons/build-community-icon.svg?inline';
import TwitterIcon from '@/assets/images/icons/twitter-icon.svg?inline';
import TelegramIcon from '@/assets/images/icons/telegram-icon.svg?inline';
import DiscordIcon from '@/assets/images/icons/discord-icon.svg?inline';

export default {
  name: 'LoyaltyPromoBuildCommunity',
  mixins: [onboardingMixin],
  components: {
    ExpansionProgressPanel,
    BuildCommunityIcon,
    DiscordIcon,
    TelegramIcon,
    TwitterIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.buildCommunityProgress
  }),
  computed: {
    socialMediaList(): any {
      return Object.freeze([
        {
          iconComponent: 'TelegramIcon',
          title: capitalize(EWidgetSocialMedia.TELEGRAM),
          subtitle: 'Announce major updates and communicate with users'
        },
        {
          iconComponent: 'TwitterIcon',
          title: capitalize(EWidgetSocialMedia.TWITTER),
          subtitle: 'Publish short posts and jokes'
        },
        {
          iconComponent: 'DiscordIcon',
          title: capitalize(EWidgetSocialMedia.DISCORD),
          subtitle: 'Create a chat for tech discussion'
        }
      ]);
    }
  },
  methods: {
    done(): void {
      this.$emit('updateProgress', this.onboardingStepName);
      this.$emit('switchExpanded', this.onboardingStepName, false);
    }
  }
};
