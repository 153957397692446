

















































import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import CreateCommunicationIcon from '@/assets/images/icons/create-communication-icon.svg?inline';
import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingUserSupport',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    CreateCommunicationIcon,
    CodeBlock
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.createAutoCommunicationProgress
  }),
  computed: {
    ...mapGetters('Onboarding', ['onboardingForm']),
    eventUrl(): string {
      return `${this.onboardingForm?.url}/player-event`;
    }
  },
  methods: {
    done(): void {
      this.$emit('updateProgress', this.onboardingStepName);
      this.$emit('switchExpanded', this.onboardingStepName, false);
    }
  }
};
